
import ButtonAdd from "@/components/utils/buttons/ButtonAdd.vue";
import { defineComponent, ref } from "vue";
import AddClinicWorkerModal from "./AddClinicWorkerModal.vue";

export default defineComponent({
  components: { ButtonAdd, AddClinicWorkerModal },
  setup() {
    const addClinicWorkerModal = ref(false);
    return { addClinicWorkerModal };
  },
});
