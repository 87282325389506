
import BaseTableWrapper from "@/components/utils/tables/BaseTableWrapper.vue";
import { computed, defineComponent, ref } from "vue";
import ClinicsWorkersListBody from "./ClinicsWorkersListBody.vue";
import ClinicsWorkersListHeader from "./ClinicsWorkersListHeader.vue";
import InputField from "@/components/utils/input/InputField.vue";
import AddClinicWorker from "@/components/clinics/workers/addworker/AddClinicWorker.vue";
import { useUserStore } from "@/store/user/UserStore";
import MaxUserCountWarningModal from "@/components/clinics/workers/utils/MaxUserCountWarningModal.vue";
import { useWorkersStore } from "@/store/workers/WorkersStore";

export default defineComponent({
  components: {
    MaxUserCountWarningModal,
    InputField,
    BaseTableWrapper,
    ClinicsWorkersListHeader,
    ClinicsWorkersListBody,
    AddClinicWorker,
  },
  setup() {
    const nameSearchInput = ref<string | undefined>(undefined);
    const userStore = useUserStore();
    const userState = userStore.getState();
    const workersStore = useWorkersStore();
    const workersState = workersStore.getState();
    const isMaxUsers = computed(() => workersState.maxWorkerCountWarning);
    const disableMaxUserCountWarning = () =>
      workersStore.setMaxWorkerCountWarning(undefined);
    return {
      nameSearchInput,
      userState,
      isMaxUsers,
      disableMaxUserCountWarning,
    };
  },
});
