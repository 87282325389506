
import MSimpleDropdown from '@/components/utils/input/dropdowns/MSimpleDropdown.vue'
import MSimpleDropdownButton from '@/components/utils/input/dropdowns/MSimpleDropdownButton.vue'
import MSimpleDropdownOption from '@/components/utils/input/dropdowns/MSimpleDropdownOption.vue'
import {computed, defineComponent, onBeforeMount, onMounted, PropType, ref, unref as v, watchEffect} from 'vue'
import {GENDER, GENDERS} from "@/shared/types";

export default defineComponent({
  components: {MSimpleDropdown, MSimpleDropdownButton, MSimpleDropdownOption},
  emits: {
    onGender: (uid: unknown) => typeof uid === 'string'
  },
  props: {
    initialGender: {
      type: String as PropType<GENDER>,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  setup(props, {emit}) {


    const genders = Object.values(GENDER)
    const selected = ref<GENDER | null>(null)
    onBeforeMount(() => {
      if (props.initialGender) selected.value = props.initialGender
    })

    const selectedGender = computed(() => v(genders).find(gender => gender === v(selected)))
    const isDropdown = ref(false)
    const dropDownId = 'gender-list-dd'
    const buttonText = computed(() => {
      return selectedGender.value ? GENDERS[selectedGender.value] : ''
    })

    const title = computed(() => selectedGender.value ? 'Sugu' : 'Palun vali sugu')
    const setIsDrowdown = (newValue: boolean) => {
      if (props.disabled) return
      isDropdown.value = newValue
    }
    onMounted(async () => {
      window.addEventListener('click', (e: MouseEvent) => {
        if (v(isDropdown)) {
          const target = e.target as HTMLDivElement
          if (!target?.closest(`#${dropDownId}`)) setIsDrowdown(false)
        }
      })
    })
    watchEffect(() => {
      if (selected.value) emit('onGender', selected.value)
    })


    return {isDropdown, dropDownId, setIsDrowdown, genders, selected, title, GENDERS, buttonText}
  }
})
