
import {AddWorkerInput} from "@/shared/types";
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  reactive,
  ref,
  toRefs,
} from "vue";
import InputField from "@/components/utils/input/InputField.vue";
import {InputFieldType} from "@/types";
import {useWorkersStore} from "@/store/workers/WorkersStore";
import {useAppStore} from "@/store/app/AppStore";
import {TYPE} from "vue-toastification";
import GenderDropdown from "@/components/workers/utils/GenderDropdown.vue";
import ClinicsDropdown from "@/components/clinics/utils/ClinicsDropdown.vue";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import {useUserStore} from "@/store/user/UserStore";
import MDatePicker from "@/components/utils/input/mdatepicker/MDatePicker.vue";

export default defineComponent({
  components: {MDatePicker, ClinicsDropdown, GenderDropdown, InputField},
  emits: {
    onWorker: (worker: unknown) => !!worker,
  },
  props: {
    worker: {
      type: Object as PropType<AddWorkerInput>,
      required: false,
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    const userStore = useUserStore()
    const userState = userStore.getState()
    const isNotAdmin = computed(() => !userState.manager)
    const showOnlyNameField = computed(() => isNotAdmin.value && props.editMode)

    const appStore = useAppStore();
    const workerAdded = ref(false);

    const clinicsStore = useClinicsStore();
    const clinicsState = clinicsStore.getState();
    const activeClinicUID = computed(() => clinicsState.activeClinicUID);

    const addNewWorkerInput = reactive<Partial<AddWorkerInput>>({
      name: undefined,
      personalNo: undefined,
      gender: undefined,
      position: undefined,
      radiationWorkStartYear: undefined,
      radiationWorkerCategory: 'B',
      registrationDetails: undefined,
      clinicUID: undefined,
      lastRadiationSafetyTrainingTime: null
    });

    const onLastRadiationSafetyTrainingTime = (date: Date) => (addNewWorkerInput.lastRadiationSafetyTrainingTime = date.getTime())

    onBeforeMount(() => {
      if (props.worker) {
        addNewWorkerInput.name = props.worker.name;
        addNewWorkerInput.personalNo = props.worker.personalNo;
        addNewWorkerInput.gender = props.worker.gender;
        addNewWorkerInput.position = props.worker.position;
        addNewWorkerInput.radiationWorkStartYear =
            props.worker.radiationWorkStartYear;
        addNewWorkerInput.radiationWorkerCategory =
            props.worker.radiationWorkerCategory;
        addNewWorkerInput.registrationDetails =
            props.worker.registrationDetails;
        addNewWorkerInput.clinicUID = props.worker.clinicUID;
        addNewWorkerInput.lastRadiationSafetyTrainingTime = props.worker.lastRadiationSafetyTrainingTime
      }
    });

    const checkAndGetNewWorker = (): AddWorkerInput | undefined => {
      const {
        name,
        personalNo,
        gender,
        position,
        radiationWorkStartYear,
        radiationWorkerCategory,
        registrationDetails,
        clinicUID,
        lastRadiationSafetyTrainingTime,
      } = toRefs(addNewWorkerInput);
      if (!name?.value)
        appStore.showToast(TYPE.WARNING, "Palun sisestage nimi");
      else if (!personalNo?.value)
        appStore.showToast(TYPE.WARNING, "Palun sisestage isikukood");
      else if (!gender?.value)
        appStore.showToast(TYPE.WARNING, "Palun sisestage sugu");
      else if (!position?.value)
        appStore.showToast(TYPE.WARNING, "Palun sisestage ametikoht");
      else if (!radiationWorkStartYear?.value)
        appStore.showToast(
            TYPE.WARNING,
            "Palun sisestage kiirgustööle asumise aasta"
        );
      else if (!radiationWorkerCategory?.value)
        appStore.showToast(
            TYPE.WARNING,
            "Palun sisestage kiirgustöötaja kategooria"
        );
      else if (!registrationDetails?.value)
        appStore.showToast(
            TYPE.WARNING,
            "Palun sisestage andmed registreerimise kohta"
        );
      else if (!clinicUID?.value)
        appStore.showToast(TYPE.WARNING, "Palun valige kliinik");
      else {
        return {
          name: name.value,
          personalNo: personalNo.value,
          gender: gender.value,
          position: position.value,
          radiationWorkStartYear: radiationWorkStartYear.value,
          radiationWorkerCategory: radiationWorkerCategory.value,
          registrationDetails: registrationDetails.value,
          clinicUID: clinicUID.value,
          lastRadiationSafetyTrainingTime: lastRadiationSafetyTrainingTime?.value ?? null
        };
      }
    };

    const onManagerAddingWorker = () => {
      const input = checkAndGetNewWorker();
      if (input) workerAdded.value = true;
    }

    const onClinicWorkerAdd = async () => isNotAdmin.value ? onManagerAddingWorker() : closeAll();

    const closeAll = () => {
      const input = checkAndGetNewWorker();
      if (input) emit("onWorker", input);
    };
    return {
      showOnlyNameField,
      onClinicWorkerAdd,
      InputFieldType,
      activeClinicUID,
      ...toRefs(addNewWorkerInput),
      workerAdded,
      closeAll,
      onLastRadiationSafetyTrainingTime

    };
  },
});
