<template>
  <thead class="bg-primary-light-blue">
    <tr>
      <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-blue-900 uppercase tracking-wider">
        Nimi
      </th>
      <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-blue-900 uppercase tracking-wider">
        UID
      </th>
      <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-blue-900 uppercase tracking-wider">
        Sugu
      </th>
      <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-blue-900 uppercase tracking-wider">
        Isikukood
      </th>
      <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-blue-900 uppercase tracking-wider">
        Kiirgustööle asumise aasta
      </th>
      <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-blue-900 uppercase tracking-wider">
        Kiirgustöötaja kategooria
      </th>
      <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-blue-900 uppercase tracking-wider">
        Andmed reg. kohta
      </th>
      <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-blue-900 uppercase tracking-wider">
        Ametikoht
      </th>
      <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-blue-900 uppercase tracking-wider">
        Järgmine koolitus
      </th>
      <th scope="col" class="align-top px-6 py-3 text-left text-xs font-medium text-blue-900 uppercase tracking-wider">
        Staatus
      </th>
      <th scope="col" class="align-top relative px-6 py-3">
        <span class="sr-only">Muuda</span>
      </th>
    </tr>
  </thead>
</template>
