
import {defineComponent, ref} from 'vue'
import ModalWrapper from '@/components/utils/modal/ModalWrapper.vue'
import ClinicWorkerForm from '@/components/forms/ClinicWorkerForm.vue'
import {AddWorkerInput} from "@/shared/types";
import {useWorkersStore} from "@/store/workers/WorkersStore";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";

export default defineComponent({
  components: {ModalWrapper, ClinicWorkerForm},
  emits: ['onClose'],
  setup() {
    const isOpen = ref(true)
    const workersStore = useWorkersStore()
    const clinicsStore = useClinicsStore()
    const onWorkerAdd = async (worker: AddWorkerInput) => {
      await workersStore.addWorker(worker)
      await clinicsStore.setClinics(true)
      isOpen.value = false
    }
    return {isOpen, onWorkerAdd}
  }
})
