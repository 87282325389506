
import {GENDERS, WorkerDto} from '@/shared/types'
import {useClinicsStore} from '@/store/clinics/ClinicsStore'
import {computed, defineComponent, PropType, ref} from 'vue'
import BaseTableEditDeleteButtons from "@/components/utils/tables/BaseTableEditDeleteButtons.vue";
import {useWorkersStore} from "@/store/workers/WorkersStore";
import EditClinicWorkerModal from "@/components/clinics/workers/EditClinicWorkerModal.vue";
import AreYouSureModal from "@/components/utils/modal/AreYouSureModal.vue";
import CopyButton from "@/components/utils/buttons/CopyButton.vue";
import Switch from "@/components/utils/buttons/Switch.vue";
import {useUserStore} from "@/store/user/UserStore";

export default defineComponent({
  components: {Switch, CopyButton, AreYouSureModal, EditClinicWorkerModal, BaseTableEditDeleteButtons},
  props: {
    worker: {
      type: Object as PropType<WorkerDto>,
      required: true
    }
  },
  setup(props) {
    const firstWarningDiff = 149895398 * 1000; // 4 years 9 months in ms
    const secondWarningDiff = 155154886 * 1000; // 4 years 11 months in ms
    const clinicsStore = useClinicsStore()
    const clinicsState = clinicsStore.getState()
    const clinic = computed(() => clinicsState.clinics.find((clinic) => props.worker.clinicUID === clinic.uid))
    const userStore = useUserStore()
    const userState = userStore.getState()
    const isAdmin = computed(() => userState.admin)
    const workersStore = useWorkersStore()

    const deleteWorker = async () => {
      await workersStore.deleteWorker({
        clinicUID: props.worker.clinicUID,
        workerUID: props.worker.uid
      })
      await clinicsStore.setClinics(true)
    }

    const deleteWorkerModal = ref(false)
    const onDeleteWorkerModalClose = async (action: boolean) => {
      if (action) await deleteWorker()
      deleteWorkerModal.value = false
    }

    const editWorkerModal = ref(false)
    const editWorker = () => editWorkerModal.value = true

    const toggleWorkerStatus = () => workersStore.toggleWorkerStatus({
      uid: props.worker.uid,
      status: !props.worker.active
    })

    const isTrainingExpiring = computed(() => {
        if (!props.worker.lastRadiationSafetyTrainingTime) return true
        return props.worker.lastRadiationSafetyTrainingTime + 157784760000 < new Date().getTime() + 86400 * 1000 * 31
    })
    const isTrainingDateCloseFirstWarning = computed(() => props.worker.lastRadiationSafetyTrainingTime ? (new Date().getTime() - props.worker.lastRadiationSafetyTrainingTime) >= firstWarningDiff : false)
    const isTrainingDateCloseSecondWarning = computed(() => props.worker.lastRadiationSafetyTrainingTime ? (new Date().getTime() - props.worker.lastRadiationSafetyTrainingTime) >= secondWarningDiff : false)
    const trainingTime = computed(() =>
        props.worker.lastRadiationSafetyTrainingTime
            ? new Date(props.worker.lastRadiationSafetyTrainingTime + 157784760000).toLocaleDateString("en-GB")
            : null
    )

    return {
      GENDERS,
      clinic,
      deleteWorkerModal,
      onDeleteWorkerModalClose,
      editWorkerModal,
      editWorker,
      toggleWorkerStatus,
      isAdmin,
      isTrainingExpiring,
      isTrainingDateCloseFirstWarning,
      isTrainingDateCloseSecondWarning,
      trainingTime
    }
  }
})
