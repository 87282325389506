
import {computed, defineComponent, PropType, ref} from 'vue'
import {AddWorkerInput, WorkerDto} from "@/shared/types";
import {useWorkersStore} from "@/store/workers/WorkersStore";
import ModalWrapper from "@/components/utils/modal/ModalWrapper.vue";
import ClinicWorkerForm from "@/components/forms/ClinicWorkerForm.vue";

export default defineComponent({
  name: "EditClinicWorkerModal",
  components: {ClinicWorkerForm, ModalWrapper},
  emits: ['onClose'],
  props: {
    worker: {
      type: Object as PropType<WorkerDto>,
      required: true
    }
  },
  setup(props, {emit}) {


    const isOpen = ref(true)
    const workersStore = useWorkersStore()
    const workerInput = computed<AddWorkerInput>(() => ({
      name: props.worker.name,
      personalNo: props.worker.personalNo,
      gender: props.worker.gender,
      position: props.worker.position,
      radiationWorkStartYear: props.worker.radiationWorkStartYear,
      radiationWorkerCategory: props.worker.radiationWorkerCategory,
      registrationDetails: props.worker.registrationDetails,
      clinicUID: props.worker.clinicUID,
      lastRadiationSafetyTrainingTime: props.worker.lastRadiationSafetyTrainingTime
    }))

    const onWorkerEdit = async (worker: AddWorkerInput) => {
      await workersStore.editWorker({uid: props.worker.uid, ...worker})
      emit('onClose')
    }
    return {isOpen, onWorkerEdit, workerInput}
  }
})
