
import MSimpleDropdown from '@/components/utils/input/dropdowns/MSimpleDropdown.vue'
import MSimpleDropdownButton from '@/components/utils/input/dropdowns/MSimpleDropdownButton.vue'
import MSimpleDropdownOption from '@/components/utils/input/dropdowns/MSimpleDropdownOption.vue'
import {computed, defineComponent, onBeforeMount, onMounted, ref, unref as v, watchEffect} from 'vue'
import {useClinicsStore} from "@/store/clinics/ClinicsStore";

export default defineComponent({
  components: {MSimpleDropdown, MSimpleDropdownButton, MSimpleDropdownOption},
  props: {
    showSelected: {
      type: Boolean,
      default: true
    },
    initialClinicUID: {
      type: String,
      required: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    showSelectAll: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    onClinic: (uid: unknown) => typeof uid === 'string',
    onDisable: () => true
  },
  setup(props, {emit}) {
    const clinicsStore = useClinicsStore()
    const clinicsState = clinicsStore.getState()
    const clinics = computed(() => clinicsState.clinics)
    const selected = ref<string | undefined>(undefined)

    watchEffect(() => selected.value = props.initialClinicUID)

    const selectedClinic = computed(() => v(clinics).find(({uid}) => uid === v(selected)))
    const isDropdown = ref(false)
    const dropDownId = 'clinics-list-dd'
    const buttonText = computed(() => selectedClinic.value?.name || '')
    const title = computed(() => selectedClinic.value ? 'Kliinik' : 'Palun vali kliinik')
    const setIsDrowdown = (newValue: boolean) => (isDropdown.value = newValue)
    onMounted(async () => {
      await clinicsStore.setClinics(false)
      window.addEventListener('click', (e: MouseEvent) => {
        if (v(isDropdown)) {
          const target = e.target as HTMLDivElement
          if (!target?.closest(`#${dropDownId}`)) setIsDrowdown(false)
        }
      })
    })
    watchEffect(() => {
      if (selected.value) emit('onClinic', selected.value)
    })

    const disableSelected = () => emit('onDisable')
    return {isDropdown, dropDownId, setIsDrowdown, clinics, selected, buttonText, disableSelected, title}
  }
})
