
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    label: {
      type: String,
      required: false
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    return {}
  }
})
