
import { defineComponent, onMounted } from "vue";
import { useWorkersStore } from "@/store/workers/WorkersStore";
import ClinicsWorkersList from "@/components/clinics/workers/ClinicsWorkersList.vue";
export default defineComponent({
  components: { ClinicsWorkersList },
  setup() {
    const workersStore = useWorkersStore();

    onMounted(async () => {
      await Promise.all([workersStore.setWorkers(false)]);
    });
    return {};
  },
});
