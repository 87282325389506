
import {computed, defineComponent, ref} from 'vue'
import ModalWrapper from "@/components/utils/modal/ModalWrapper.vue";
import {useWorkersStore} from "@/store/workers/WorkersStore";
import {MaxWorkerCountWarningType} from "@/store/workers/types";

export default defineComponent({
  name: "MaxUserCountWarningModal",
  components: {ModalWrapper},
  emits: {
    onClose: () => true
  },
  setup(_, {emit}) {
    const isOpen = ref(true)
    const onClose = () => emit('onClose')
    const workersStore = useWorkersStore()
    const workersState = workersStore.getState()
    const warningType = computed(() => workersState.maxWorkerCountWarning)
    const isAddNewWarning = computed(() => warningType.value === MaxWorkerCountWarningType.ADD_NEW)
    return {isOpen, onClose,isAddNewWarning}
  }
})
