
import BaseTableBodyWrapper from '@/components/utils/tables/BaseTableBodyWrapper.vue'
import {useWorkersStore} from '@/store/workers/WorkersStore'
import {defineComponent, computed} from 'vue'
import ClinicsWorkerListItem from './ClinicsWorkerListItem.vue'
import {useClinicsStore} from "@/store/clinics/ClinicsStore";

export default defineComponent({
  components: {BaseTableBodyWrapper, ClinicsWorkerListItem},
  props: {
    nameFilterInput: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const clinicsStore = useClinicsStore()
    const clinicsState = clinicsStore.getState()
    const activeClinicUID = computed(() => clinicsState.activeClinicUID)
    const workersStore = useWorkersStore()
    const workersState = workersStore.getState()
    const allWorkers = computed(() => workersState.workers)
    const workers = computed(() => activeClinicUID.value ? allWorkers.value.filter(worker => worker.clinicUID === activeClinicUID.value) : allWorkers.value)
    const filteredWorkers = computed(
        () => props.nameFilterInput?.length
            ? workers.value.filter(w => w.name.toLowerCase().includes((props.nameFilterInput || '').toLowerCase()))
            : workers.value)
    const sortedWorkers = computed(() => filteredWorkers.value.slice().sort((a, b) => b.clinicUID.localeCompare(a.clinicUID)).sort(v => v.active ? -1 : 1))
    return {sortedWorkers}
  }
})
